import { UtilRand } from "../../rand"

export namespace modules {
  export function pika(index: number) {
    const name = `textShadowPika${index}`
    const keyframes = `@keyframes ${name} {
  50%{
    text-shadow: 10px 20px 30px red;
  }
}
`
    return {
      name,
      keyframes
    }
  }
}

export default modules