
export namespace UtilRand {
  export function num(max: number) {
    return Math.floor(Math.random() * (1 + max))
  }

  export function float(max: number) {
    return Math.random() * (1 + max)
  }

  export function array(list: any[]) {
    const i = UtilRand.num(list.length - 1)
    return list[i]
  }

  export function color(min = 220, addAlpha = false) {
    const r = (Math.floor((Math.random() *255 - min)) + min).toString(16).padStart(2, '0')
    const g = (Math.floor((Math.random() *255 - min)) + min).toString(16).padStart(2, '0')
    const b = (Math.floor((Math.random() *255 - min)) + min).toString(16).padStart(2, '0')
    const a = addAlpha ? (Math.floor((Math.random() *255 - min)) + min).toString(16).padStart(2, '0') : ""
    return "#" + r + g + b + a
  }
}
