import React, { useEffect, useLayoutEffect, useState } from 'react';
import { UtilRand } from '../../utils/rand';
import { Animix, UtilAnimation } from '../../utils/animations/index';
import { AnimationTimingFunction } from '../../utils/animations/props';

function getRandomColors() {
  const colorB = UtilRand.color(220, true)
  let set = [
    colorB,
    colorB,
    colorB,
    colorB,
    colorB,
    colorB,
  ]

  return set
}

function App(w: number, h: number, intervalSec: number) {
  const ratioRa = 77 / 99
  const ratioTsu = 55 / 56
  const ratioKu = 72 / 98
  const sizeRatioRa = 5
  const sizeRatioTsu = 6.7
  const sizeRatioKu = 5.4
  const padding = w / 70

  const [ styleAnima, setStyleAnima] = useState("")

  const [ colors, setColors ] = useState<string[]>(getRandomColors())

  let rotate = true

  useEffect(() => {
    const intervalId = setInterval(() => {
      setColors(getRandomColors())
    }, intervalSec * 1000)

    const intervalId2 = setInterval(() => {
      const params = [
        [10, 200, 200, 90],
        [10, 200, 200, 50],
        [undefined, 0, 0, 80],
      ]
      if (rotate) {
        let style: string = ""
        for(let i = 0; i < 5; i++ ) {
          const param = UtilRand.array(params)
          const animix = new Animix()
          let scale = 1
          if (param[0]) scale = UtilRand.num(param[0])
          let x = UtilRand.num(param[1])
          if (UtilRand.num(1) === 1) x *= -1
          let y = UtilRand.num(param[2])
          if (UtilRand.num(1) === 1) y *= -1
          let deg = UtilRand.num(350)
          if (UtilRand.num(1) === 1) deg *= -1
          animix.addStyle(UtilAnimation.rotate3d.gyun(i, param[3], scale, x, y, "", deg), intervalSec, AnimationTimingFunction.linear, true)
          // animix.addStyle(UtilAnimation.text.shadow.pika(i), intervalSec, AnimationTimingFunction.linear, true)
          style += animix.getStyle(`custom_animix_${i}`)
        }
        setStyleAnima(style)
        rotate = false
      } else {
        setStyleAnima("")
        rotate = true
      }
    }, intervalSec * 1000)

    return () => {
      clearInterval(intervalId)
      clearInterval(intervalId2)
    };
  }, []);
  
  return (
    <div style={{width: w, height: h}}>
      <style>
      {styleAnima}
      </style>
      <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
        style={{
          position: "absolute",
          top: padding,
          left: padding
        }}
        className='custom_animix_0'
        width={w / sizeRatioRa} height={w / sizeRatioRa / ratioRa}
        viewBox="0 0 77.000000 99.000000"
        preserveAspectRatio="xMidYMid meet">
        <g transform={`translate(0.000000,99.000000) scale(0.100000,-0.100000)`}
          // rotate="5deg"
          fill={colors[1]} stroke="none"
          style={{
            WebkitTransition: `all ${intervalSec}s ease`,
            MozTransition: `all ${intervalSec}s ease`,
          }}
        >
        <path d="M133 980 c-12 -5 -26 -18 -32 -29 -16 -30 -13 -152 4 -175 18 -23 5
        -36 -36 -36 -14 0 -35 -10 -47 -23 -21 -22 -22 -33 -22 -189 0 -152 2 -167 21
        -192 19 -24 26 -26 97 -26 74 0 81 2 147 43 111 69 167 82 225 52 51 -26 54
        -104 7 -139 -26 -19 -43 -21 -204 -23 -214 -2 -213 -1 -213 -108 0 -55 4 -78
        17 -94 26 -31 116 -44 258 -38 188 8 294 51 365 149 41 56 53 108 48 203 -6
        124 -56 202 -162 257 -60 32 -218 37 -303 10 -34 -11 -65 -17 -68 -14 -3 3 -5
        22 -5 42 0 22 -8 48 -20 63 -26 33 -25 38 4 33 13 -2 105 -6 206 -8 217 -4
        210 -7 210 112 0 105 -14 118 -141 130 -118 12 -327 12 -356 0z"/>
        </g>
      </svg>
      <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
        style={{
          position: "absolute",
          top: w / 9,
          left: w / 4.3
        }}
        className='custom_animix_1'
        width={w / sizeRatioTsu} height={w / sizeRatioTsu / ratioTsu}
        viewBox="0 0 55.000000 56.000000"
        preserveAspectRatio="xMidYMid meet">
        <g transform="translate(0.000000,56.000000) scale(0.100000,-0.100000)"
          fill={colors[2]} stroke="none"
          style={{
            WebkitTransition: `all ${intervalSec}s ease`,
            MozTransition: `all ${intervalSec}s ease`,
          }}
        >
        <path d="M112 544 c-97 -26 -112 -44 -112 -133 0 -86 33 -95 204 -58 75 17
        113 11 133 -20 15 -22 15 -27 0 -58 -20 -42 -58 -62 -150 -80 -85 -16 -107
        -36 -107 -100 0 -28 7 -51 22 -70 21 -27 22 -28 109 -21 159 12 244 54 303
        149 56 91 44 253 -27 334 -51 58 -95 73 -217 72 -58 0 -129 -7 -158 -15z"/>
        </g>
      </svg>
      <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
        style={{
          position: "absolute",
          top: padding,
          left: w / 4.3 + w / sizeRatioTsu + padding / 2
        }}
        className='custom_animix_2'
        width={w / sizeRatioKu} height={w / sizeRatioKu / ratioKu}
        viewBox="0 0 72.000000 98.000000"
        preserveAspectRatio="xMidYMid meet">

        <g transform="translate(0.000000,98.000000) scale(0.100000,-0.100000)"
          fill={colors[3]} stroke="none"
          style={{
            WebkitTransition: `all ${intervalSec}s ease`,
            MozTransition: `all ${intervalSec}s ease`,
          }}
        >
        <path d="M448 970 c-16 -5 -47 -23 -70 -41 -86 -67 -274 -228 -310 -265 -69
        -72 -86 -160 -47 -240 22 -47 139 -163 324 -321 l119 -103 113 0 c104 0 113 2
        127 22 36 52 45 41 -378 439 l-60 57 35 32 c19 17 116 97 217 177 183 147 208
        175 185 212 -5 9 -21 22 -34 28 -27 14 -178 16 -221 3z"/>
        </g>
      </svg>
      <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
        style={{
          position: "absolute",
          top: padding,
          left: w / 4.3 + w / sizeRatioTsu + padding / 2 + w / sizeRatioKu + padding / 2
        }}
        className='custom_animix_3'
        width={w / sizeRatioRa} height={w / sizeRatioRa / ratioRa}
        viewBox="0 0 77.000000 99.000000"
        preserveAspectRatio="xMidYMid meet">
        <g transform="translate(0.000000,99.000000) scale(0.100000,-0.100000)"
          fill={colors[4]} stroke="none"
          style={{
            WebkitTransition: `all ${intervalSec}s ease`,
            MozTransition: `all ${intervalSec}s ease`,
          }}
        >
        <path d="M133 980 c-12 -5 -26 -18 -32 -29 -16 -30 -13 -152 4 -175 18 -23 5
        -36 -36 -36 -14 0 -35 -10 -47 -23 -21 -22 -22 -33 -22 -189 0 -152 2 -167 21
        -192 19 -24 26 -26 97 -26 74 0 81 2 147 43 111 69 167 82 225 52 51 -26 54
        -104 7 -139 -26 -19 -43 -21 -204 -23 -214 -2 -213 -1 -213 -108 0 -55 4 -78
        17 -94 26 -31 116 -44 258 -38 188 8 294 51 365 149 41 56 53 108 48 203 -6
        124 -56 202 -162 257 -60 32 -218 37 -303 10 -34 -11 -65 -17 -68 -14 -3 3 -5
        22 -5 42 0 22 -8 48 -20 63 -26 33 -25 38 4 33 13 -2 105 -6 206 -8 217 -4
        210 -7 210 112 0 105 -14 118 -141 130 -118 12 -327 12 -356 0z"/>
        </g>
      </svg>
      <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
        style={{
          position: "absolute",
          top: padding,
          left: w / 4.3 + w / sizeRatioTsu + padding / 2 + w / sizeRatioKu + padding / 2 + w / sizeRatioRa + padding
        }}
        className='custom_animix_4'
        width={w / sizeRatioKu} height={w / sizeRatioKu / ratioKu}
        viewBox="0 0 72.000000 98.000000"
        preserveAspectRatio="xMidYMid meet">
        <g transform="translate(0.000000,98.000000) scale(0.100000,-0.100000)"
          fill={colors[5]} stroke="none"
          style={{
            WebkitTransition: `all ${intervalSec}s ease`,
            MozTransition: `all ${intervalSec}s ease`,
          }}
        >
        <path d="M448 970 c-16 -5 -47 -23 -70 -41 -86 -67 -274 -228 -310 -265 -69
        -72 -86 -160 -47 -240 22 -47 139 -163 324 -321 l119 -103 113 0 c104 0 113 2
        127 22 36 52 45 41 -378 439 l-60 57 35 32 c19 17 116 97 217 177 183 147 208
        175 185 212 -5 9 -21 22 -34 28 -27 14 -178 16 -221 3z"/>
        </g>
      </svg>
    </div>
  );
}

export default App;
