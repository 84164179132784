import React, { useEffect, useLayoutEffect, useState } from 'react';
import { UtilRand } from '../../utils/rand';
import { Animix, UtilAnimation } from '../../utils/animations/index';
import { AnimationTimingFunction } from '../../utils/animations/props';

function App(w: number, h: number, fontFamily: string) {
  const [now, setNow] = useState(new Date())

  useEffect(() => {
    const intevalIdTime = setInterval(async () => {
      setNow(new Date())
    }, 1000)

    return () => {
      clearInterval(intevalIdTime);
    };
  }, []);

  const nowTime = now.toLocaleDateString("ja-JP", {year: "numeric",month: "2-digit",
    day: "2-digit", hour: "2-digit",minute: "2-digit",
    second: "2-digit"}).substring(10)

  let nowDate = now.toLocaleDateString("ja-JP", {year: "numeric",month: "2-digit",
    day: "2-digit"})

  const panelTimeWidth = w / 15

  nowDate += `(${['sun','mon','tue','wed','thu','fri','sat'][now.getDay()]})`;

  const panelTimeCSS: React.CSSProperties = {
    fontSize: h / 4,
    // backgroundColor: "#000000", 
    fontFamily: fontFamily, 
    textAlign:"justify", 
    width: panelTimeWidth, 
    textAlignLast: "center",
    float: "left",
    opacity: 0.9
    // marginLeft: w / 60
  }

  const panelDate: JSX.Element[] = []
  const panelTime: JSX.Element[] = []

  for(const s of nowDate.split("")) {
    panelDate.push((
      <div style={panelTimeCSS}>
        {s}
      </div>
    ))
  }

  for(const s of nowTime.split("")) {
    panelTime.push((
      <div style={panelTimeCSS}>
        {s}
      </div>
    ))
  }

  return (
    <>
      <div style={{fontSize: h / 2.5 }}>
        {panelDate}
      </div>
      <div>
        {panelTime}
      </div>
    </>
  );
}

export default App;
