import React, { useEffect, useLayoutEffect, useState } from 'react';
// import LogoBack from "./assets/logo/back.svg";
import './App.css';
import Westasabu from './effects/logo/westasabu'
import Rotate3d from './effects/logo/rotate3d'
import TextSplash from './effects/text/splash'
import ClockA from './effects/clock/a'
import { UtilRand } from './utils/rand';

const fontFamilies = [
  "MoiraiOne-Regular",
  // "RubikPuddles-Regular",
  // "FasterOne-Regular"
]

const fontFamily = fontFamilies[Math.floor(Math.random() * (fontFamilies.length))]

const sai = UtilRand.num(1)

function App() {
  const width = window.innerWidth - 100
  const ratio = 400 / 114
  const [w, setW] = useState(width);
  const [h, setH] = useState(width / ratio);
  const intervalSec = UtilRand.num(9) + 1;

  useLayoutEffect(() => {
    const updateSize = (): void => {
      const width = window.innerWidth - 100
      setW(width)
      setH(width / ratio)
    };
    window.addEventListener('resize', updateSize);
    window.addEventListener('orientationchange', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  let xPanelMaxHeight = "38vh"

  if (
    window.screen.orientation.type === "portrait-primary" ||
    window.screen.orientation.type === "portrait-secondary"
  ) {
    xPanelMaxHeight = "33vh"
  }

  type SplashText = {
    text: string
    numMax: number
  }

  const splashDefines: SplashText[] = [
    {text: "synergy", numMax: 3},
    {text: "pop", numMax: 3},
    {text: "rock", numMax: 5},
    {text: "☆", numMax: 1},
    {text: "!", numMax: 2},
    {text: "?", numMax: 3},
    {text: "sustinable", numMax: 2},
    {text: "try and error", numMax: 5},
    // {text: "don't repeat your self", numMax: 1},
    {text: "eureka", numMax: 1},
  ]

  const splashTextCount = 30
  const splashTexts: SplashText[] = []

  for (let i = 0; i < splashTextCount; i++) {
    splashTexts.push(UtilRand.array(splashDefines))
  }

  return (
    <div className="App" style={{position: "relative", overflow: "hidden"}}>
      <div style={{
        position: "absolute", 
        bottom: 0, 
        right: 0, 
        maxHeight: xPanelMaxHeight,
        overflowY: "scroll", 
        zIndex: 4, 
        borderTopLeftRadius: "1vw",
        borderTopRightRadius: "1vw"
      }}>
        <a 
          className="twitter-timeline"
          href="https://twitter.com/rakkuraku_x"
          data-lang="ja"
          data-width={250}
          data-chrome="nofooter noborders transparent"
          data-tweet-limit="3"
          style={{backgroundColor: "#ffff3377",
            borderTopLeftRadius: "10px"
          }}
        >Tweets by @rakkuraku_x</a>
      </div>
      <header className="App-header" style={{position: "relative",}}>
        <div style={{position: "relative", opacity: 0.9}}>
          {
            splashTexts.map((text, i) => {
              return TextSplash(w, h, UtilRand.num(9) + 10, fontFamily, text.text, text.numMax, `_splash_${i}`)
            })
          }
          {
            Rotate3d(w, h, intervalSec)
          }
        </div>
        {ClockA(w, h, fontFamily)}
      </header>
    </div>
  );
}

export default App;
