import { AnimationTimingFunction } from './props'
import Rotate3d from './rotate3d'
import TextShadow from './text/shadow'

export namespace UtilAnimation {
  export const rotate3d = Rotate3d
  export namespace text {
    export const shadow = TextShadow
  }
}

export type AnimixStyle = {
  name: string,
  keyframes: string
}

export class Animix {
  rotate3d = Rotate3d
  styleKeyframes: string[] = []
  styleAnimations: string[] = []

  addStyleAnimation(name: string, interval = 1, timingFunction: AnimationTimingFunction = AnimationTimingFunction.linear, infinite = true) {  
    const styleAnimation = `${name} ${interval}s${infinite ? " infinite": ""} ${AnimationTimingFunction[timingFunction]}`
    this.styleAnimations.push(styleAnimation)
  }

  addStyleKeyframes(keyframe: string) {
    this.styleKeyframes.push(keyframe)
  }

  addStyle(style: AnimixStyle, interval = 1, timingFunction: AnimationTimingFunction = AnimationTimingFunction.linear, infinite = true) {
    this.addStyleKeyframes(style.keyframes)
    this.addStyleAnimation(style.name, interval, timingFunction, infinite)
  }

  getStyle(name: string) {
    let style = this.styleKeyframes.join("\n")

    style += `.${name} {
  animation: ${this.styleAnimations.join(",")};
}
`
    return style
  }
}
